'use strict';

angular.module('kljDigitalLibraryApp')
    .controller('CounterCtrl', ["$scope", "$uibModalInstance", "counter", "$state",
        function($scope, $uibModalInstance, counter, $state) {

            $scope.counter = counter;

            $scope.close = function() {
                console.log("no");
                $uibModalInstance.close();
            }

            $scope.renewSubscription = function(id) {
                $uibModalInstance.close();
                $state.go('index.order', {
                    subscription: id
                });
            }

        }
    ]);